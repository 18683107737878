<template>
  <v-card class="docs">
    <v-card-title class="two-columns">
      <div class="text-h5 text-center">Загрузка внешнего рейтинга</div>
    </v-card-title>

    <div class="list">
      <div>
        <v-text-field v-model="ratingName" label="Название внешнего рейтинга"></v-text-field>
      </div>
      <a href="#" @click.prevent="$refs['fileupload'].click()">Загрузить файл</a>
      <input type="file" multiple ref="fileupload"
             style="display:none"
             @change="importFile($event)">
      <p>{{importResultText}}</p>
    </div>

    <v-card-actions>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Отмена
      </v-btn>
      <v-btn
          class="btn btn-primary"
          @click="submit()"
          :disabled="!importOk || !ratingName"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import api from "@/modules/api";

import readXlsxFile from "read-excel-file";
import {save} from "@/modules/ExternalRating";

export default {
  name: 'IntegralMonitorParamsDialog',
  props: ['period'],

  data: () => ({
    ratingName: "",
    importResultText: null,
    importOk: false,
    ratingRows: []
  }),
  methods: {
    async importFile(event){
      let rows = await readXlsxFile(event.target.files[0])
      rows.shift()
      for(let row of rows){
        if(row.length > 2){
          this.importResultText = 'Некорректный состав полей'
          this.importOk = false
          return false
        }
      }
      let unknownRegionNames = this.findUndefinedRegions(rows)
      if(unknownRegionNames.length > 0){
        this.importResultText = 'Не найдены следующие субъекты РФ: ' + unknownRegionNames.join(', ')
        this.importOk = false
        return false
      }
      this.importResultText = 'Файл содержит рейтинги по ' + rows.length + ' субъектам РФ'
      this.importOk = true
      this.ratingRows = rows
      return true
    },

    findUndefinedRegions(rows){
      let unknownRegionNames = []
      for(let row of rows){
        let regionName = row[0]
        let existingRegion = this.$cznDepartmentsByRegionList.find(e=>e.name===regionName)
        if(existingRegion == null) {
          unknownRegionNames.push(regionName)
        }
        return unknownRegionNames
      }
    },

    async submit(){
      let externalRating = {
        name: this.ratingName,
        period: this.period,
        ratingJson: JSON.stringify(this.ratingRows)
      }
      console.log(externalRating)
      await save(externalRating)
      this.$emit('close')
    }

  },
  filters: {},
  async beforeMount() {
  }
}
</script>

<style lang="scss">
.v-dialog {
  .v-card.docs {
    .v-card__title.two-columns {
      justify-content: space-between;
      align-items: center;
    }

    .list {
      padding: 0 24px;

      table {
        th {
          min-width: 20% !important;
        }

        th, td {
          border-bottom: 0 !important;
          padding: 5px 0 !important;
          padding-right: 10px !important;

          &:last-child {
            padding-right: 0 !important;
          }
        }
      }

      .v-text-field {
        margin-top: 0;
      }

      .v-text-field:not(.v-textarea):not(.v-file-input) .v-input__slot {
        height: initial;
        margin: 0;
        padding: 5px 5px;
      }
    }
  }
}
</style>



