<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded || errorText">
        <div v-if="configs.length===0">
          <v-btn outlined @click="$router.push('/config/ratings2023')">
            создать конфигурацию
          </v-btn>
        </div>
        <div v-else>
          <h3>Интегральные рейтинги</h3>
          <div class="list">
            <div class="report-list">
              <ResultMonitorRating
                  v-for="(rating, ratingIndex) in integralRatings"
                  :key="'integral_rating'+ratingIndex"
                  :title="(ratingIndex+1) + ' ' + rating.title"
                  :period="period"
                  :config="rating.config"
                  :report-data-function="rating.dataFunction"
                  :legend="rating.legend"
              ></ResultMonitorRating>
            </div>
          </div>

        </div>
      </div>
    </transition>

  </div>
</template>


<script>

import ResultMonitorRating from "@/components/resultmonitor_new/ResultMonitorRating";

import {loadConfigsByMonitoringType} from "@/modules/Rating2023Config";
import {createDataFunction, setDepartmentsByRegion, setPeriod} from "@/modules/IntegralRating";

export default {
  name: "IntegralMonitorRatings",
  components: {ResultMonitorRating},
  props: ['period'],
  watch: {
    period: {
      async handler() {
        this.apiLoaded = false;
        await this.initialize();
        this.$forceUpdate();
        this.apiLoaded = true;
      }
    }
  },
  data: () => ({
    id: null,
    apiLoaded: false,
    errorText: null,
    integralRatings: [],
    configs: []
  }),

  methods: {

    async initialize() {
      let configs = await loadConfigsByMonitoringType('INTEGRAL')
      this.configs = configs.filter(e => e.period === this.period)
      this.integralRatings = []
      for(let config of this.configs){
        let configParsed = JSON.parse(config.configJson)
        this.integralRatings.push({
          title: configParsed.title,
          config: config,
          dataFunction: await createDataFunction(configParsed.components)
        })
      }
      setDepartmentsByRegion(this.$cznDepartmentsByRegionList)
      setPeriod(this.period)
    }
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    await this.initialize()
    this.apiLoaded = true;
  },
}
</script>

<style scoped lang="scss">
h1 {
  display: flex;
  align-items: center;

  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }

  .v-progress-circular {
    margin-left: 20px;
  }

  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  table {
    margin-bottom: 25px;

    th {
      border-bottom: 1px solid #E8E8E9;
    }

    td:nth-child(2),
    th:nth-child(2) {
      text-align: right;
    }

    td > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      svg {
        margin-right: 8px;
        flex-shrink: 0;
        margin-top: 5px;
      }
    }

    tr:last-child td {
      border-bottom: 1px solid #E8E8E9;
    }
  }
}

.push-right {
  float: right;
  margin-top: 15px;
}

.report-list {
  border-top: 1px solid #e8e8e9;

  .report-list__item {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e9;
    padding: 5px 0;

    .report-list__title {
      flex: 0 0 calc(100% - 40px);

      .toggleSwitch {
        margin-right: 5px;
      }

      span {
        color: $cblue;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

    }

    .report-list__download {
      background: url(~@/assets/download.svg) no-repeat center center;
      background-size: contain;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .report-list__graph {
      width: 400px;
      max-width: 100%;
      height: 200px;
      background: $cgray;
      margin-bottom: 10px;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: none;
  border: 1px solid #A0A0A0;
  border-radius: 0;

  .downloads {
    border-radius: 0;

    .v-list {
      padding: 0;
      background: #E9E9E9;

      .v-list-item {
        padding: 0;
        min-height: initial;

        .v-list-item__title {
          padding: 4px 8px;
          color: $cblue;
        }
      }
    }
  }
}

</style>
