<template>
  <div v-cloak v-if="apiLoaded">
    <div class="block">
      <div class="list">
        <div class="list">
          <div class="report-list">
            <ResultMonitorReport
                v-for="(report, reportIndex) in reports"
                :key="'regional_report_'+reportIndex"
                :title="(reportIndex+1) + ' ' + report.name"
                :period="period"
                :report-data-function="report.dataFunction"
            ></ResultMonitorReport>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ResultMonitorReport from "@/components/resultmonitor_new/ResultMonitorReport";
import {createReportDataFunction, setDepartmentsByRegion, setPeriod} from "@/modules/IntegralRating";
import {loadConfigsByMonitoringType} from "@/modules/Rating2023Config";

export default {
  name: "IntegralMonitoringReports",
  components: {
    ResultMonitorReport
  },
  props: ['period'],
  watch: {
    period: {
      async handler() {
        this.apiLoaded = false;
        await this.initialize();
        this.$forceUpdate();
        this.apiLoaded = true;
      }
    }
  },
  data: () => ({
    showLoaderDialog: false,
    apiLoaded: false,

    reports: [
    ],
  }),

  methods: {
    async initialize(){
      let configs = await loadConfigsByMonitoringType('INTEGRAL')
      this.configs = configs.filter(e => e.period === this.period)
      this.reports = []
      for(let config of this.configs) {
        let configParsed = JSON.parse(config.configJson)
        this.reports.push({
          name: 'Отчет по интегральному показателю "' + configParsed.title + '"',
          dataFunction: await createReportDataFunction(configParsed)
        })
      }
    }
  },
  async beforeMount() {
    setDepartmentsByRegion(this.$cznDepartmentsByRegionList)
    setPeriod(this.period)
    await this.initialize()
    this.apiLoaded = true;
    this.show = true;
    this.expandedPanels = [];

  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";

.top-progress {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;

    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }

    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}

.line-wrap {
  border: 1px solid #E8E8E9;
  border-radius: 4px;
}

.list {
  .list-item-wrap {
    padding: 0;
  }

  table {
    margin-bottom: 25px;

    th {
      padding: 5px 20px 5px 0;

      div.v-dialog__container + div {
        height: 24px;
        cursor: pointer;
      }

      span {
        display: flex;
        justify-content: flex-start;
        gap: 3px;
        min-width: min(150px, 20vw);
      }

      &:first-child {
        text-align: center;
        width: 100%;
      }
    }

    td {
      padding: 5px 20px 5px 0;
      border-top: 1px solid #E8E8E9;
      border-bottom: 0;

      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
          margin-right: 8px;
          flex-shrink: 0;
          margin-top: 5px;
          width: 20px;
        }

        a {
          white-space: nowrap;
        }
      }
    }

    tr:last-child {
      td {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.report-list {
  border-top: 1px solid #e8e8e9;

  .report-list__item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e9;
    padding: 5px 0;

    .report-list__title {
      .toggleSwitch {
        margin-right: 5px;
      }

      span {
        color: $cblue;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

    }

    .report-list__download {
      background: url(~@/assets/download.svg) no-repeat center center;
      background-size: contain;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: none;
  border: 1px solid #A0A0A0;
  border-radius: 0;

  .downloads {
    border-radius: 0;

    .v-list {
      padding: 0;
      background: #E9E9E9;

      .v-list-item {
        padding: 0;
        min-height: initial;

        .v-list-item__title {
          padding: 4px 8px;
          color: $cblue;
        }
      }
    }
  }
}

</style>
