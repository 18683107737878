<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded" class="wrap">
        <v-dialog
            v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'"
            v-model="externalRatingUploadDialog"
            max-width="800px"
            persistent>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn outlined>
                Загрузить рейтинг
              </v-btn>
            </div>
          </template>
          <ExternalRatingUploadDialog
              :period="period"
              v-if="externalRatingUploadDialog"
              @close="externalRatingUploadDialog=false;initialize();"
          ></ExternalRatingUploadDialog>
        </v-dialog>
        <div class="docs-table">
          <table style="max-width: 500px">
            <tr>
              <th>Рейтинг</th>
              <th>Дата загрузки</th>
            </tr>
            <tr v-for="rating in externalRatings" :key="rating.id">
              <td>{{ rating.name }}</td>
              <td>{{ rating.creationDateTime | dateFormat }}</td>
            </tr>
          </table>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>

import ExternalRatingUploadDialog from "@/components/integralmonitor/ExternalRatingUploadDialog";
import {loadByPeriod} from "@/modules/ExternalRating";

export default {
  name: "IntegralMonitorCheckList",
  components: {
    ExternalRatingUploadDialog,
  },
  props: ['period'],
  watch: {
    period: {
      async handler() {
        this.apiLoaded = false;
        await this.initialize();
        this.$forceUpdate();
        this.apiLoaded = true;
      }
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  data: () => ({
    apiLoaded: false,
    externalRatings: [],
    externalRatingUploadDialog: false
  }),
  methods: {
    async initialize() {
      this.externalRatings = await loadByPeriod(this.period)
    },
  },
  async beforeMount() {
    await this.initialize()
    this.apiLoaded = true;
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/main";

h1 {
  display: flex;
  align-items: center;

  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }

  .v-progress-circular {
    margin-left: 20px;
  }

  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  .list-item-wrap {
    padding: 5px 0;
  }

  table {
    margin-bottom: 25px;

    th {
      padding: 5px 20px 5px 0;

      div.v-dialog__container + div {
        height: 24px;
        cursor: pointer;
      }

      span {
        display: flex;
        justify-content: flex-start;
        gap: 3px;
        min-width: min(150px, 20vw);
      }

      &:first-child {
        text-align: center;
        width: 100%;
      }
    }

    td {
      padding: 5px 20px 5px 0;
      border-top: 1px solid #E8E8E9;
      border-bottom: 0;

      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
          margin-right: 8px;
          flex-shrink: 0;
          margin-top: 5px;
          width: 20px;
        }

        a {
          white-space: nowrap;
        }
      }
    }

    tr:last-child {
      td {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.clientindex {
  .v-expansion-panel.level2 {
    padding-left: 30px;
    padding-top: 0px;

    .list-item-wrap {
      border-bottom: 0;
    }

    & + .v-expansion-panel.level2 {
      .list-item-wrap {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.push-right {
  float: right;
  margin-top: 15px;
}

.v-progress-circular__info {
  font-size: 13px !important;
  text-align: center;
  width: 100%;
}
</style>
